import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Columns from "../components/columns"
import SEO from "../components/seo"
import Header from "../components/header"
import Main from "../components/main"
import Text, { HeaderText2, Label } from '../components/text';
import PageWrapper from "../components/pageWrapper"
import Hero from "../components/hero"
import rem from "../utils/rem"
import flex from "../styles/flex"


const TermsAndConditionsPage = ({ data }) => (
  <Layout>
    <SEO title="Velectronic Custom Electronic Development" />
    <Header siteTitle={data.site.siteMetadata.title}>
      <BackgroundImage
        Tag="section"
        fluid={data.headerImage.childImageSharp.fluid}
        backgroundColor="#040e18"
        css={{ backgroundSize: "cover" }}
      >
        <Hero type="even" color="#fff" id="hero-2" nextId="terms-and-conditions" scrollDownText="Scroll Down to See details">
          <PageWrapper style={{ ...flex.vertical, textAlign: "center" }}>
            <Text
              tag="h2"
              size="xxlg"
              weight="semibold"
              style={{ color: "#fff" }}
            >
              Terms &amp; conditions
            </Text>
          </PageWrapper>
        </Hero>
      </BackgroundImage>
    </Header>
    <Main>
      <section id="terms-and-conditions">
        <PageWrapper style={{ ...flex.vertical, textAlign: "center" }}>
          <header>
            <HeaderText2>
              Terms and conditions
            </HeaderText2>
          </header>
          <Columns count="1" style={{
            padding: `${rem(50)} 0`,
            textAlign: "left",
          }}>
            <Label >Limited Warranty</Label>
            <Text>
              V Electronic warrants its products during one year from the purchase date, always within its facilities.
              This warranty does not cover any damage caused by misuse of the product or any modification.
              In case of repair, transport costs are borne by the customer.
            </Text>
            <Text>
              V Electronic reserves the right to determine what qualifies as misuse at their discretion.
            </Text>
            <Text>Examples of misuse include, but are not limited to:</Text>
            <Text tag='ul'>
              <li>Damage to the product caused by excessive physical force or impact.</li>
              <li>Exposure to extreme heat or moisture.</li>
              <li>Connecting the product input and/or output to a power supply with voltage greater than the nominal one.</li>
              <li>The RV identification tag is missing or illegible.</li>
              <li>That the device shows evidence of having been tampered with or disassembled.</li>
            </Text>

            <Label>Repairs</Label>
            <Text>
              To return a product for repair or replacement, please e-mail info@velectronic.net indicating the RV reference on the label and a description of the anomalies observed.
              <br />If necessary, we will issue a Return Merchandise Authorization (RMA) with our address.
              Be sure to properly package your product with the original packaging and clearly mark the RMA number on the box before shipping your product.
              Failure to adequately protect and package a product during shipping will void warranty.
            </Text>
            <Text>Products not under warranty may be repaired at our hourly service rates of 50€ per hour.
              V Electronic reserves the right to refuse to repair any product not covered by warranty at their discretion.
            </Text>
          </Columns>
        </PageWrapper>
      </section>
    </Main>
  </Layout>
)

export default TermsAndConditionsPage

export const pageQuery = graphql`
  query TermsAndConditionsQuery {
    site {
      siteMetadata {
        title
      }
    }
    headerImage: file(relativePath: { eq: "bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    module: file(relativePath: { eq: "a-envelope.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contentImage: file(relativePath: { eq: "product_image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
